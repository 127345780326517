import React, { forwardRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = forwardRef(({ value, onChange, ...props }, ref) => {
    return (
        <div className="rich-text-editor">
            <ReactQuill
                ref={ref}
                value={value}
                onChange={onChange}
                {...props}
            />
        </div>
    );
});

export default RichTextEditor;
